// ...other imports
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import CustomModal from './customModal';

function Setting() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userData, setData] = useState([]);
  const [addEmail, setAddEmail]=useState("");
  const [addRole, setAddRole]=useState("");
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [editUserId, setEditUserId] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null); // Track user to delete


  const getUserData = async () => {
    try {
      const res = await axios.get(`/api/user/users`);
      console.log(res.data, "response of user data");
      setData(res.data.users || []);
    } catch (error) {
      console.error("Error in fetching user data", error);
      toast.error("Failed to fetch user data");
    }
  };

  useEffect(() => {
    getUserData();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(addEmail, addRole,"testing data");
    try {
      if (editUserId) {
        await axios.put(`${backendUrl}/api/user/edit/${editUserId}`, {
          email, 
          role
        });
        toast.success('User updated successfully!');
      } else {
        await axios.post(`${backendUrl}/api/user/create`, {
          email: addEmail,
          role: addRole,

      })
      toast.success('User added successfully!');
    }
      setAddRole("");
      setAddEmail("");
      setEditUserId(null);
      setEditModalOpen(false); // Close modal after submission
      getUserData();
    } catch (error) {
      toast.error('Error saving user. Please try again.');
      console.error('Error creating/updating user:', error);
    }
  };

  const handleEdit = (user) => {
    setEmail(user.email);
    setRole(user.role);
    setEditUserId(user._id);
    setEditModalOpen(true); // Open edit modal
  };

  const handleDelete = (userId) => {
    setUserToDelete(userId);
    setDeleteModalOpen(true); // Open delete confirmation modal
  };

  const confirmDelete = async (confirm) => {
    if (confirm && userToDelete) {
      try {
        await axios.delete(`/api/user/delete/${userToDelete}`);
        toast.success('User deleted successfully!');
        getUserData(); // Refresh user list
      } catch (error) {
        toast.error('Error deleting user. Please try again.');
        console.error('Error deleting user:', error);
      }
    }
    setDeleteModalOpen(false); // Close modal regardless of confirm action
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm mb-6">
        <h2 className="text-2xl font-bold text-center mb-6">Add User</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            value={addEmail}
            onChange={(e) => setAddEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-base focus:border-primary-base sm:text-sm"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
          <select
            id="role"
            value={addRole}
            onChange={(e) => setAddRole(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-base focus:border-primary-base sm:text-sm"
            required
          >
            <option value="">Select a role</option>
            <option value="admin">Admin</option>
            <option value="editor">Editor</option>
          </select>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="w-full px-4 py-2 bg-primary-base text-white rounded-md transition"
          >
          Submit
          </button>
        </div>
      </form>

      <div className="w-full max-w-3xl">
        {/* <h2 className="text-xl font-bold mb-4">User List</h2> */}
        <table className="min-w-full bg-white border border-gray-300 mt-10">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Role</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user) => (
              <tr key={user._id}>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b text-center">{user.role}</td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    onClick={() => handleEdit(user)}
                    className="text-blue-500 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(user._id)}
                    className="ml-4 text-red-500 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit User Modal */}
      <CustomModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        title={editUserId ? "Edit User" : "Add User"}
        type={"edit"}
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-base focus:border-primary-base sm:text-sm"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-base focus:border-primary-base sm:text-sm"
              required
            >
              <option value="">Select a role</option>
              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
            </select>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={() => setEditModalOpen(false)}
              className="mr-2 text-gray-500 hover:underline"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-primary-base text-white px-4 py-2 rounded-md"
            >
              {editUserId ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </CustomModal>

      {/* Delete Confirmation Modal */}
      <CustomModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)} // Only close modal without confirming
        title="Confirm Deletion"
        type={"edit"}
      >
        <p>Are you sure you want to delete this user?</p>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={() => confirmDelete(false)} // Pass false to indicate cancellation
            className="mr-2 text-gray-500 hover:underline"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => confirmDelete(true)} // Confirm deletion
            className="bg-primary-base text-white px-4 py-2 rounded-md"
          >
            Confirm
          </button>
        </div>
      </CustomModal>
    </div>
  );
}

export default Setting;
