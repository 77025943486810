import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import CustomerDetailsSkeleton from "./skeletons/CustomerDetailsSkeleton";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "./skeletons/Spinner";
import { useLocation } from "react-router-dom";

const CustomerDetail = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  console.log(customer, "Customer declined services");

  const location = useLocation();

  const [type, setType] = useState(null);

  useEffect(() => {
    console.log(location, "location of customer in details");
    setType(location?.state?.type);
    setCustomer(location?.state?.customer);
  }, [location]);

  console.log("update datae", customer);

  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updateData, setUpdateData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: [""],
    phones: [{ number: "", type: "Mobile", primary: true }],
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    notes: "",
    contactFirstName: "",
    contactLastName: "",
    okForMarketing: false,
    customerType: "",
    tags: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        console.log(customer, "cusotmer data");

        // Convert email to array if it's a string
        const emailData = Array.isArray(customer.data.email)
          ? customer.data.email
          : customer.data.email
          ? [customer.data.email] // Convert single email string to array
          : [""];

        const phoneData =
          customer.data.phone && Array.isArray(customer.data.phone)
            ? customer.data.phone
            : [{ number: "", type: "Mobile", primary: true }];

        setCustomer(customer.data);
        setUpdateData({
          id: customer.id,
          shopId: customer.data.shopId,
          firstName: customer.data.firstName || "",
          lastName: customer.data.lastName || "",
          email: emailData,
          phones: phoneData,
          address: {
            address1: customer.data.address?.address1 || "",
            address2: customer.data.address?.address2 || "",
            city: customer.data.address?.city || "",
            state: customer.data.address?.state || "",
            zip: customer.data.address?.zip || "",
          },
          notes: customer.data.notes || "",
          contactFirstName: customer.data.contactFirstName || "",
          contactLastName: customer.data.contactLastName || "",
          okForMarketing: customer.data.okForMarketing || false,
          customerType: customer.data.customerType?.name || "", // Assuming type name is editable
          tags: customer.declinedServices?.tags || "",
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer:", error);
        setError(error.response?.data?.error || "Failed to fetch customer");
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customer]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);

    if (!isEditing && customer) {
      const emailData = Array.isArray(customer.email)
        ? customer.email
        : customer.email
        ? [customer.email]
        : [""];

      // Ensure customer.phone is structured as an array
      const phoneData = Array.isArray(customer.phone)
        ? customer.phone.map((phone) => ({
            number: phone.number || "", // Map phone number
            type: phone.type || "Mobile", // Map phone type
            primary: phone.primary || false, // Map primary status
          }))
        : [{ number: "", type: "Mobile", primary: true }]; // Default empty phone data

      setUpdateData({
        id: customer.id || "",
        shopId: customer.shopId || "",
        firstName: customer.firstName || "",
        lastName: customer.lastName || "",
        email: emailData,
        phones: phoneData, // Set phones correctly here
        address: {
          address1: customer.address?.address1 || "",
          address2: customer.address?.address2 || "",
          city: customer.address?.city || "",
          state: customer.address?.state || "",
          zip: customer.address?.zip || "",
        },
        notes: customer.notes || "",
        contactFirstName: customer.contactFirstName || "",
        contactLastName: customer.contactLastName || "",
        okForMarketing: customer.okForMarketing || false,
        customerType: customer.customerType?.name || "",
        tags: customer.declinedServices?.tags || "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name in updateData.address) {
      setUpdateData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value,
        },
      }));
    } else {
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...updateData.email];
    updatedEmails[index] = value;
    setUpdateData((prevData) => ({
      ...prevData,
      email: updatedEmails,
    }));
  };

  const handleTagChange = (index, value) => {
    const updatedTags = [...updateData.tags];
    updatedTags[index] = value;

    setUpdateData((prevData) => ({
      ...prevData,
      tags: updatedTags,
    }));
  };

  const handlePhoneChange = (index, value, field) => {
    const updatedPhones = [...updateData.phones];
    updatedPhones[index] = { ...updatedPhones[index], [field]: value };
    setUpdateData((prevData) => ({
      ...prevData,
      phones: updatedPhones,
    }));
  };

  const addEmailField = () => {
    setUpdateData((prevData) => ({
      ...prevData,
      email: [...prevData.email, ""],
    }));
  };

  const removeEmailField = (index) => {
    const updatedEmails = updateData.email.filter((_, i) => i !== index);
    setUpdateData((prevData) => ({
      ...prevData,
      email: updatedEmails,
    }));
  };

  const addPhoneField = () => {
    setUpdateData((prevData) => ({
      ...prevData,
      phones: [
        ...prevData.phones,
        { number: "", type: "Mobile", primary: false },
      ],
    }));
  };

  const removePhoneField = (index) => {
    const updatedPhones = updateData.phones.filter((_, i) => i !== index);
    setUpdateData((prevData) => ({
      ...prevData,
      phones: updatedPhones,
    }));
  };

  const handleUpdate = async () => {
    setUpdateLoading(true);
    try {
      const response = await axios.patch(
        `/api/customers/${customerId}`,
        updateData
      );


      console.log(type,"type of dataaaaaa");
    
      if (type === "Declined") {
      
        try {


          let shopId = customer?.shopId;
            
          
          const response = await axios.get(
            `/api/saveDeclinedJob`,{
              params:{customerId,shopId}
            } // Pass the customerId and shopId
          );

          console.log(response.data, "Declined jobs fetched and processed");
        } catch (error) {
          console.error("Error fetching declined jobs:", error.message);
        }
      }
      setCustomer(response.data);

      // setIsEditing(false);
      toast.success("Customer updated successfully");
      setUpdateLoading(false);
    } catch (error) {
      console.error("Error updating customer:", error);
      setError(error.response?.data?.error || "Failed to update customer");
      toast.error(error.response?.data?.error || "Failed to update customer");
      setUpdateLoading(false);
    }
    // window.location.reload(); // Refresh the page after a successful update
  };
  useEffect(() => {
    console.log(updateData, "updated ata");
  }, [updateData]);

  if (loading) return <CustomerDetailsSkeleton />;

  // if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Customer Details</h2>
      {isEditing ? (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={updateData.firstName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={updateData.lastName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700">Emails:</label>
            {updateData.email.map((email, index) => (
              <div key={index} className="flex items-center space-x-2 mb-2">
                <input
                  type="email"
                  name={`email-${index}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {updateData.email.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeEmailField(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addEmailField}
              className="text-blue-500 hover:text-blue-700"
            >
              Add Email
            </button>
          </div>

          <div>
            <label className="block text-gray-700">Phones:</label>
            {updateData.phones.map((phone, index) => (
              <div
                key={index}
                className="grid grid-cols-4 gap-2 items-center mb-2"
              >
                <input
                  type="text"
                  name={`phone-number-${index}`}
                  placeholder="Number"
                  value={phone.number}
                  onChange={(e) =>
                    handlePhoneChange(index, e.target.value, "number")
                  }
                  className="col-span-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <select
                  name={`phone-type-${index}`}
                  value={phone.type}
                  onChange={(e) =>
                    handlePhoneChange(index, e.target.value, "type")
                  }
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="Mobile">Mobile</option>
                  <option value="Home">Home</option>
                  <option value="Work">Work</option>
                </select>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={`phone-primary-${index}`}
                    checked={phone.primary}
                    onChange={(e) =>
                      handlePhoneChange(index, e.target.checked, "primary")
                    }
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />{" "}
                  <span>Primary</span>
                  {updateData.phones.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removePhoneField(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addPhoneField}
              className="text-blue-500 hover:text-blue-700"
            >
              Add Phone
            </button>
          </div>

          <div>
            <label className="block text-gray-700">Address:</label>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="address1"
                placeholder="Address Line 1"
                value={updateData.address.address1}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="address2"
                placeholder="Address Line 2"
                value={updateData.address.address2}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="grid grid-cols-3 gap-4 mt-2">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={updateData.address.city}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={updateData.address.state}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="zip"
                placeholder="Zip Code"
                value={updateData.address.zip}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* <div>
            <label className="block text-gray-700">Notes:</label>
            <textarea
              name="notes"
              value={updateData.notes}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div> */}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">Contact First Name:</label>
              <input
                type="text"
                name="contactFirstName"
                value={updateData.contactFirstName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Contact Last Name:</label>
              <input
                type="text"
                name="contactLastName"
                value={updateData.contactLastName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700">Customer Type:</label>
            <input
              type="text"
              name="customerType"
              value={updateData.customerType}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="okForMarketing"
              checked={updateData.okForMarketing}
              onChange={(e) =>
                handleChange({
                  target: { name: "okForMarketing", value: e.target.checked },
                })
              }
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label className="text-gray-700">Ok for Marketing</label>
          </div>

          {updateData?.tags && (
            <div className="flex flex-col space-y-2">
              <label className="block text-gray-700 font-medium mb-2">
                Tags:
              </label>
              <div className="flex flex-wrap gap-3">
                {updateData?.tags.map((tag, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-100 px-2 py-1 rounded-lg shadow-sm"
                  >
                    <input
                      type="text"
                      id={`tag-${index}`}
                      value={tag}
                      onChange={(e) => handleTagChange(index, e.target.value)}
                      className="px-3 py-1 text-sm border-none rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                      style={{ minWidth: "450px" }} // Adjust the min width as needed
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4 mt-4">
            <button
              onClick={handleUpdate}
              className="bg-primary-base text-white px-6 py-2 rounded-lg "
            >
              {updateLoading ? <Spinner /> : "Save"}
            </button>
            <button
              onClick={handleEditToggle}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-4">
          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Name:</p>
            <p className="text-lg">
              {customer.firstName} {customer.lastName}
            </p>
          </div>

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Emails:</p>
            <ul className="list-none space-y-1">
              {/* {Array.isArray(customer.email) && customer.email.length > 0 ? ( */}
              {/* customer.email.map((email, index) => ( */}
              <li className="text-gray-700">
                {customer?.email ? (
                  <a
                    href={`mailto:${customer?.email}`}
                    className="text-blue-500 hover:underline"
                  >
                    {customer?.email}
                  </a>
                ) : (
                  "No email availible"
                )}
              </li>
              {/* ))
      ) : (
        <li className="text-gray-700">No email available</li>
      )} */}
            </ul>
          </div>

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Phones:</p>
            <ul className="list-none space-y-1">
              {customer.phone &&
              Array.isArray(customer.phone) &&
              customer.phone.length > 0 ? (
                customer.phone.map((phone, index) => (
                  <li key={index} className="">
                    {phone.number} ({phone.type}) {phone.primary && "(Primary)"}
                  </li>
                ))
              ) : (
                <li className="text-gray-700">No phone available</li>
              )}
            </ul>
          </div>

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Address:</p>
            <p className="text-lg">
              {customer.address?.fullAddress || "No address available"}
            </p>
          </div>

          {/* <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Notes:</p>
            <p className="text-lg">{customer.notes || "No notes available"}</p>
          </div> */}

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Contact Name:</p>
            <p className="text-lg">
              {customer.contactFirstName} {customer.contactLastName}
            </p>
          </div>

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Customer Type:</p>
            <p className="text-lg">{customer.customerType?.name || "N/A"}</p>
          </div>

          <div className="flex items-start">
            <p className="text-lg font-semibold w-48">Marketing Opt-In:</p>
            <p className="text-lg">{customer.okForMarketing ? "Yes" : "No"}</p>
          </div>

          {customer.declinedServices && customer.declinedServices?.tags && (
            <div className="flex items-start">
              <p className="text-lg font-semibold w-48">Tags:</p>
              <p className="text-lg">
                {customer.declinedServices?.tags.length > 0
                  ? customer.declinedServices?.tags.map((tag, index) => (
                      <span key={index} className="mr-2">
                        {tag} <br />
                      </span>
                    ))
                  : "N/A"}
              </p>
            </div>
          )}

          {customer.appointments && (
            <>
              <p className="text-lg font-bold w-50">Appointment Details</p>
              {customer.appointments.map((appointment, index) => (
                <div key={index} className="mb-4">
                  <div className="flex items-start">
                    <p className="text-lg font-semibold w-48">Description:</p>
                    <p className="text-lg">
                      {appointment.description || "N/A"}
                    </p>
                  </div>
                  <div className="flex items-start">
                    <p className="text-lg font-semibold w-48">Start Time:</p>
                    <p className="text-lg">{appointment.startTime || "N/A"}</p>
                  </div>
                  <div className="flex items-start">
                    <p className="text-lg font-semibold w-48">Created Date:</p>
                    <p className="text-lg">
                      {appointment.createdDate || "N/A"}
                    </p>
                  </div>
                  <div className="flex items-start">
                    <p className="text-lg font-semibold w-48">Status:</p>
                    <p className="text-lg">
                      {appointment.appointmentStatus || "N/A"}
                    </p>
                  </div>
                </div>
              ))}
            </>
          )}

          {customer.vehicleTags && customer.vehicleTags.length > 0 && (
            <>
              <div className="flex items-start">
                <p className="text-lg font-semibold w-48">Customer Type:</p>
                <p className="text-lg">
                  {customer.customerType?.name || "N/A"}
                </p>
              </div>
              <div className="flex items-start">
                <p className="text-lg font-semibold w-48">Vehicle Tags:</p>
                <div className="flex flex-wrap">
                  {customer.vehicleTags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-blue-200 text-blue-800 px-2 py-1 rounded-full mr-2 mb-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="flex space-x-4 justify-end mt-6">
            <button
              onClick={handleEditToggle}
              className="bg-primary-base text-white px-6 py-2 rounded-lg "
            >
              Edit
            </button>
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetail;
