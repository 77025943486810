import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const LocationUpdateForm = () => {
  const [locations, setLocations] = useState([]);
  const [shopName, setShopName] = useState('');
  const [tekmetricId, setTekmetricId] = useState('');
  const [ghlId, setGhlId] = useState('');
  const [ghlToken, setGhlToken] = useState(''); // New State for GHL Token
  const [calendarId, setCalendarId] = useState(''); // New State for GHL Token
  const [calendarUserId, setCalendarUserId] = useState(''); 
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // Function to fetch locations
  const fetchLocations = async () => {
    try {
      const response = await axios.get('/api/location');
      setLocations(response.data); // Store fetched locations in state
    } catch (error) {
      setError('Error fetching locations');
    }
  };

  // Fetch locations when the component mounts
  useEffect(() => {
    fetchLocations(); // Fetch locations on initial render
  }, []);

  // Handle form submission for new location
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setError('');

    try {
      const response = await axios.post('/api/location', {
        shopName,
        tekmetricId,
        ghlId,
        ghlToken // Include GHL Token
      });

      setShopName('');
      setTekmetricId('');
      setGhlId('');
      setGhlToken(''); // Reset GHL Token after submission
      setCalendarId(''); // Reset GHL Token after submission

      // Show success toast
      toast.success('Location ID created successfully!');
      fetchLocations();
    } catch (error) {
      setError('Error creating Location ID. Please try again.');

      // Show error toast
      toast.error('Error creating Location ID. Please try again');
    }
  };

  // Handle updating a specific location
  const handleUpdate = async (locationId, updatedLocation) => {
    console.log(locationId, updatedLocation);
    try {
      const response = await axios.put(`/api/location/update/${locationId}`, updatedLocation);
      console.log('Response from server:', response.data);
      toast.success('Location updated successfully');
      fetchLocations();
    } catch (error) {
      console.error('Error updating location:', error);
      setMessage(error.response?.data?.error || 'Failed to update location');
    }
  };

  // Handle input change for the locations in the list
  const handleInputChange = (index, field, value) => {
    const updatedLocations = [...locations];
    updatedLocations[index][field] = value;
    setLocations(updatedLocations);
  };

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-primary-base mb-6">Manage Locations</h1>
      
      <form onSubmit={handleSubmit} className="bg-white pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="shopName">
            Shop Name
          </label>
          <input
            id="shopName"
            type="text"
            placeholder="Enter Shop Name"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tekmetricId">
            Tekmetric ID
          </label>
          <input
            id="tekmetricId"
            type="text"
            placeholder="Enter Tekmetric ID"
            value={tekmetricId}
            onChange={(e) => setTekmetricId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ghlId">
            GHL ID
          </label>
          <input
            id="ghlId"
            type="text"
            placeholder="Enter GHL ID"
            value={ghlId}
            onChange={(e) => setGhlId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ghlToken">
            GHL Token
          </label>
          <input
            id="ghlToken"
            type="text"
            placeholder="Enter GHL Token"
            value={ghlToken}
            onChange={(e) => setGhlToken(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ghlToken">
            Calendar ID
          </label>
          <input
            id="calendarId"
            type="text"
            placeholder="Enter Calendar ID"
            value={calendarId}
            onChange={(e) => setCalendarId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ghlToken">
            Calendar User ID
          </label>
          <input
            id="calendarUserId"
            type="text"
            placeholder="Enter Calendar ID"
            value={calendarUserId}
            onChange={(e) => setCalendarUserId(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

        <div className='flex justify-end w-full'>
          <button
            type="submit"
            className="bg-primary-base text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Location ID
          </button>
        </div>
      </form>

      {message && <p className="text-green-600 mb-6">{message}</p>}

      <h2 className="text-2xl font-semibold text-primary-base mb-4">Existing Locations</h2>
     
      {/* List of locations with input fields */}
      <div className="mt-6">
        <ul className="list-none">
          {locations.length > 0 ? (
            locations.map((location, index) => (
              <li key={location._id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`shopName-${location._id}`}>
                    Shop Name
                  </label>
                  <input
                    id={`shopName-${location._id}`}
                    type="text"
                    value={location.shopName}
                    onChange={(e) => handleInputChange(index, 'shopName', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`tekmetricId-${location._id}`}>
                    Tekmetric ID
                  </label>
                  <input
                    id={`tekmetricId-${location._id}`}
                    type="text"
                    value={location.tekmetricId}
                    onChange={(e) => handleInputChange(index, 'tekmetricId', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`ghlId-${location._id}`}>
                    GHL ID
                  </label>
                  <input
                    id={`ghlId-${location._id}`}
                    type="text"
                    value={location.ghlId}
                    onChange={(e) => handleInputChange(index, 'ghlId', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`ghlToken-${location._id}`}>
                    GHL Token
                  </label>
                  <input
                    id={`ghlToken-${location._id}`}
                    type="text"
                    value={location.ghlToken}
                    onChange={(e) => handleInputChange(index, 'ghlToken', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`ghlToken-${location._id}`}>
                    Calendar ID
                  </label>
                  <input
                    id={`calendarId-${location._id}`}
                    type="text"
                    value={location.calendarId}
                    onChange={(e) => handleInputChange(index, 'calendarId', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`ghlToken-${location._id}`}>
                    Calendar User ID
                  </label>
                  <input
                    id={`calendarUserId-${location._id}`}
                    type="text"
                    value={location.calendarUserId}
                    onChange={(e) => handleInputChange(index, 'calendarUserId', e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>

                <div className='flex mt-10 justify-end w-full'>
                  <button
                    onClick={() => handleUpdate(location._id, location)}
                    className="bg-primary-base text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Update
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p>No locations found.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LocationUpdateForm;
