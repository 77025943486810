import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for createRoot
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';

// Create a root element for the React application
const container = document.getElementById('root');

// Create a root with React 18's createRoot API
const root = ReactDOM.createRoot(container);

// Render the app using the new root
root.render(
  <React.StrictMode>
    <App />
    <Toaster /> 
  </React.StrictMode>
);
