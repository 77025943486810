import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import SkeletonTable from './skeletons/SkeletonTable';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const CustomerList = () => {
  const { shopId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages] = useState(1000); // Total number of pages for now
  const location = useLocation();
  const { type } = location.state || {};

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        let response;
        if (type === "Declined") {
          response = await axios.get(`/api/decCustomers`, {
            params: { shopId, pageNumber }
          });
          console.log(response?.data, "response data");
          setCustomers(response?.data);
        }else if(type ==="Appointments"){
          // alert("here");
          response= await axios.get('/api/get-appointments',{
            params:{shopId, pageNumber}
          })
          console.log(response?.data, "response data");
          setCustomers(response?.data);
        }
        
        else {
          response = await axios.get(`/api/customers`, {
            params: { shopId }
          });
          setCustomers(response?.data.content);
          console.log(response?.data.content);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching customers:', error);
        setError(error.response?.data?.error || 'Failed to fetch customers');
        setLoading(false);
      }
    };

    if (shopId) {
      fetchCustomers();
    }
  }, [shopId, pageNumber, type]);

  const handlePageChange = (newPage) => {
    setLoading(true);
    setPageNumber(newPage);
  };

  const handleCheckboxChange = (customer) => {
    setSelectedCustomers((prevSelected) => {
      if (prevSelected.some((c) => c.id === customer.id)) {
        return prevSelected.filter((c) => c.id !== customer.id);
      } else {
        return [...prevSelected, customer];
      }
    });
  };

  const handleSaveSelected = async () => {
    try {
      let endpoint = type === "Declined" ? '/api/saveDecCustomer' : '/api/saveCustomers';
      await axios.post(endpoint, {
        customers: selectedCustomers,
      });
      toast.success('Customers saved successfully!');
    } catch (error) {
      console.error('Error saving selected customers:', error);
      toast.error('Failed to save customers.');
    }
  };

  const renderPagination = () => (
    <div className="flex items-center justify-center space-x-2 mt-4">
      <button
        onClick={() => handlePageChange(Math.max(pageNumber - 1, 1))}
        className="py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
        disabled={pageNumber === 1}
      >
        &larr; Previous
      </button>
      <span className="px-4">{`Page ${pageNumber} of ${totalPages}`}</span>
      <button
        onClick={() => handlePageChange(Math.min(pageNumber + 1, totalPages))}
        className="py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
        disabled={pageNumber === totalPages}
      >
        Next &rarr;
      </button>
    </div>
  );


  
  const handleViewEdit = (customer) => {
    // Navigate to the customer details page and pass customer data as state
    navigate(`/customers/${customer.id}`, { state: { customer,type } });
  };

  if (loading) return <SkeletonTable />;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto bg-white shadow-md rounded my-6 p-4 sm:p-6">
      <Toaster />
      <h2 className="text-xl text-primary-base font-bold mb-4">
        {type} Customers for Shop ID: {shopId}
      </h2>

      <button
        onClick={handleSaveSelected}
        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mb-4"
        disabled={selectedCustomers.length === 0}
      >
        Save Selected Customers
      </button>

      <div className="max-w-full overflow-x-auto">
        <table className="min-w-full w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-xs sm:text-sm leading-normal">
              <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Select</th>
              <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Customer Details</th>
              <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Contact Info</th>
              <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Address</th>
              {type === "Declined" && (
                <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Declined Services</th>
              )}
              <th className="py-2 sm:py-3 px-2 sm:px-6 text-center">Action</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-xs sm:text-sm font-light">
            {customers && customers.map((customer) => (
              <tr key={customer.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                  <input
                    type="checkbox"
                    checked={selectedCustomers.some((c) => c.id === customer.id)}
                    onChange={() => handleCheckboxChange(customer)}
                  />
                </td>
                <td className="py-2 sm:py-3 px-2 sm:px-6 text-left whitespace-normal">
                  <h2 className="font-semibold">{customer.firstName} {customer.lastName}</h2>
                  {customer.nickname && (
                    <p className="text-sm text-gray-600">
                      <strong>Nickname:</strong> {customer.nickname}
                    </p>
                  )}
                </td>
                <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                  <p>{customer.phone[0]?.number || 'N/A'}</p>
                  <p>
                    <a
                      href={`mailto:${customer.email}`}
                      className="text-blue-500 hover:underline"
                    >
                      {customer.email}
                    </a>
                  </p>
                  {customer.website && (
                    <p>
                      <a
                        href={`https://${customer.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {customer?.website}
                      </a>
                    </p>
                  )}
                </td>
                <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                  <p>{customer.address?.fullAddress || 'N/A'}</p>
                </td>
                {type === "Declined" && (
                  <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                    {/* {customer?.declinedServices?.slice(0, 2).join(', ') || 'No services declined'} */}
                    {customer?.declinedServices.service_name
}
                  </td>
                )}
                <td className="py-2 sm:py-3 px-2 sm:px-6 text-center">
                <button
      onClick={()=>{handleViewEdit(customer)}}
      className="bg-primary-base text-white py-1 px-3 rounded hover:bg-primary-dark"
    >
      View & Edit
    </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {renderPagination()}
    </div>
  );
};

export default CustomerList;
