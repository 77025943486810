import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AiOutlineHome, AiOutlineShop } from 'react-icons/ai';
import { RiSecurePaymentLine } from 'react-icons/ri';
import ShopList from './components/ShopList';
import ApiTokenForm from './components/ApiTokenForm';
import LocationUpdateForm from './components/LocationUpdateForm';
import CustomerList from './components/CustomerList';
import DeclinedServiceList from './components/declinedServicesList';
import AppointmentsList from './components/AppointmentsList';
import CustomerDetail from './components/CustomerDetail';
// import AppointmentsList from './components/AppointmentsList';
import AppointmentItem from './components/AppointmentItem';

import AppointmentDetail from './components/AppointmentDetail';
import { IoSettingsOutline, IoAccessibility } from "react-icons/io5";
import axios from 'axios';
import Setting from './pages/Setting/Setting';
import { ROLES } from './constants/roles'; 
import { toast } from 'react-hot-toast'; // Import react-hot-toast


function App() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [userRole, setUserRole] = useState(null);


  // Fetch authenticated user data from the backend
  useEffect(() => {
    axios.get(`${backendUrl}/auth/user`, { withCredentials: true })
      .then((response) => {
        setUser(response.data.user);  // Store the user info if authenticated
        setUserRole(response.data.role);
        setAvatar(response.data.user.photos[0]?.value);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error('Unauthorized access.');
        }
        setUser(null);  // No user, not authenticated
      });
  }, [backendUrl]);
 
  return (
    <Router>
      <div className="min-h-screen flex ">
        {/* Sidebar */}
        <aside className="w-full max-w-64 min-w-64 bg-white shadow-lg p-6 space-y-4">
          <Link to="/" className="text-center text-primary-base text-2xl font-bold mb-8 ">
            <img src="https://cdn.prod.website-files.com/6659dfba7a583d4883d4186a/666b3f4a01058ef52f958db3_Campanellas%20Logo%20v6%20Black.svg" alt="logo" />
          </Link>

          {/* Display Login/Logout based on user's authentication state */}
          <div className="text-center mb-8">
            {user ? (
              <div className="flex flex-col items-center space-y-4">
                <img src={avatar} alt="Avatar" className="w-24 h-24 rounded-full" />
                <p className="text-lg font-semibold">Hello, {user.displayName}</p>
                <button
                  className="p-2 bg-red-500 text-white rounded"
                  onClick={() => (window.location.href = backendUrl+'/auth/logout')}
                >
                  Logout
                </button>
              </div>
            ) : (
              <button
                className="p-2 bg-blue-500 text-white rounded"
                onClick={() => (window.location.href = backendUrl+'/auth/google')}
              >
                Login with Google
              </button>
            )}
          </div>

          <nav className="space-y-2 t-6">
            <Link to="/" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
              <AiOutlineHome className="text-2xl text-primary-base" />
              <span className="text-gray-800 text-md">Home</span>
            </Link>
            {user && (
              <>
                <Link to="/shops" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <AiOutlineShop className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-md">All Customers Shop List</span>
                </Link>
                <Link to="/declined-orders" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <AiOutlineShop className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-md">Declined Jobs Shop List</span>
                </Link>


                <Link to="/appointments" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <AiOutlineShop className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-md">Appointments List</span>
                </Link>

                {/* Show Settings only if user role is 'admin' */}
                {userRole === ROLES.ADMIN && (
                  <Link to="/settings" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                    <IoSettingsOutline className="text-2xl text-primary-base" />
                    <span className="text-gray-800 text-md">Settings</span>
                  </Link>
                )}

                {/* Show Settings only if user role is 'admin' */}
                {userRole === ROLES.ADMIN && (
                  <Link to="/locations" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                    <IoAccessibility className="text-2xl text-primary-base" />
                    <span className="text-gray-800 text-md">Locations</span>
                  </Link>
                )}
              </>
            )}
          </nav>
        </aside>

        {/* Main Content Area */}
        <div className="flex-1 p-8">
          <Routes>
            <Route path="/shops" element={<ShopList />} />
            <Route path="/declined-orders" element={<DeclinedServiceList />} /> 
            <Route path="/appointments" element={<AppointmentsList />} />
            <Route path="/shops/:shopId/customers" element={<CustomerList />} />
            <Route path="/customers/:customerId" element={<CustomerDetail />} />
            <Route path="/shops/:shopId/appointments" element={<AppointmentDetail />} />
            {/* Only accessible if admin */}
            {userRole === ROLES.ADMIN && <Route path="/settings" element={<Setting />} />}
            {userRole === ROLES.ADMIN && <Route path="/locations" element={<LocationUpdateForm />} />}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
