import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppointmentItem from './AppointmentItem';
import SkeletonTable from './skeletons/SkeletonTable';

const AppointmentsList = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalContacts, setTotalContacts] = useState(0);
  const [savedContacts, setSavedContacts] = useState(0);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false); // Control visibility of progress details


  useEffect(() => {
    const fetchShops = async () => {
      try {
        const response = await axios.get(backendUrl+'/api/shops');
        // const response = await axios.get(`/api/shops`, {
        //   headers: {
        //     Authorization: `Bearer ${yourApiToken}`,
        //   },
        //   withCredentials: true,
        // })
        setShops(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching shops:', error);
        setError(error.response?.data?.error || 'Failed to fetch shops');
        setLoading(false);
      }
    };

    fetchShops();
  }, [backendUrl]);



  return (
    <div >
      <h1 className='text-primary-base font-semibold text-lg'>Shop List</h1>
      {loading ? (
        <SkeletonTable />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="container w-full mx-auto bg-white shadow-md rounded my-6">
        <table className="min-w-max w-full bg-white table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Timings</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3  text-center">Status</th>
              <th className="py-3 px-6 text-center">Ride Option</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {shops.map((shop) => (
              <AppointmentItem key={shop.id} shop={shop} setTotalContacts={setTotalContacts} setSavedContacts={setSavedContacts} setStatus={setStatus} setError={setError} setShowDetails={setShowDetails}/>
            ))}
          </tbody>
        </table>
      </div>
      )}

{/* {showDetails && 
  <div className="mt-4 w-full flex flex-col items-end">
    <div className='flex flex-col  w-full max-w-[240px]'>
    <p className="text-lg font-semibold text-blue-600">Total Contacts: <span className="text-gray-800">{totalContacts}</span></p>
    <p className="text-lg font-semibold text-green-600">Saved Contacts: <span className="text-gray-800">{savedContacts}</span></p>
    <p className={`text-lg font-semibold ${status === 'completed' ? 'text-green-500' : 'text-yellow-500'}`}>
      Status: <span className="text-gray-800 capitalize">{status}</span>
    </p>
    {error && <p className="text-lg text-red-500 font-semibold">Error: {error}</p>}
    </div>
  </div>
} */}
      
    </div>
  );
};

export default AppointmentsList;
