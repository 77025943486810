import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const AppointmentItem = () => {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { shopId } = useParams();

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/get-appointments/${shopId}`);
      if (response.status === 200) {
        setAppointments(response.data);
      }
    } catch (error) {
      console.error("Error fetching appointment data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [expandedDescription, setExpandedDescription] = useState({});

  const toggleDescription = (id) => {
    setExpandedDescription((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    fetchAppointments(); // Fetch the appointments on component mount
  }, [shopId]); // Update the dependency to shopId instead of appointmentId

  if (loading) {
    return <p>Loading appointment data...</p>;
  }

  if (appointments.length === 0) {
    return <p>No appointment data available.</p>;
  }

  // Filter the appointments based on the search term (case-insensitive)
  const filteredAppointments = appointments.filter((appointment) =>
    appointment.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    appointment.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="container w-full mx-auto bg-white shadow-md rounded my-6">
        {/* Search Bar */}
        <div className="py-4 px-6">
          <input
            type="text"
            placeholder="Search appointments..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        
        {/* Table */}
        <table className="min-w-max w-full bg-white table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Timings</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 text-center">Status</th>
              <th className="py-3 px-6 text-center">Ride Option</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {filteredAppointments.map((appointment) => (
              <tr key={appointment._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-2 text-left max-w-[150px]">
                  <h2 className="font-semibold text-sm sm:text-base">{appointment.title || "No Title"}</h2>
                </td>

                <td className="py-3 px-2 text-left max-w-[200px]">
                  <p className="text-xs sm:text-sm">Start: {new Date(appointment.startTime).toLocaleString()}</p>
                  <p className="text-xs sm:text-sm">End: {new Date(appointment.endTime).toLocaleString()}</p>
                </td>

                <td className="py-3 px-2 text-left max-w-[200px]">
                  <p className="whitespace-pre-wrap break-words">
                    {expandedDescription[appointment._id] ? (
                      appointment.description
                    ) : (
                      <>
                        {appointment.description?.slice(0, 200) || "No Description"}{" "}
                        {appointment.description?.length > 200 && (
                          <button
                            onClick={() => toggleDescription(appointment._id)}
                            className="text-blue-500 underline"
                          >
                            See More
                          </button>
                        )}
                      </>
                    )}
                  </p>
                  {expandedDescription[appointment._id] && (
                    <button
                      onClick={() => toggleDescription(appointment._id)}
                      className="text-blue-500 underline"
                    >
                      See Less
                    </button>
                  )}
                </td>

                <td className="py-3 px-2 text-center text-xs sm:text-sm">
                  <p
                    className={`font-semibold ${
                      appointment.appointmentStatus === "completed"
                        ? "text-green-500"
                        : appointment.appointmentStatus === "failed"
                        ? "text-red-500"
                        : "text-yellow-500"
                    }`}
                  >
                    {appointment.appointmentStatus}
                  </p>
                </td>

                <td className="py-3 px-2 text-center max-w-[150px]">
                  <p className="text-xs sm:text-sm">
                    Ride Option: {appointment.rideOption?.name || "None"}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AppointmentItem;
