import React from 'react';

const CustomerDetailsSkeleton = () => {
  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <div className="animate-pulse">
        <div className="h-8 bg-gray-300 rounded mb-6 w-1/3"></div>
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              {/* Name Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-2/3 mb-4"></div>
              
              {/* Email Skeleton */}
              <div className="flex items-start space-x-2 mb-4">
                <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                <ul className="space-y-2 w-full">
                  <li className="h-4 bg-gray-300 rounded w-1/2"></li>
                  <li className="h-4 bg-gray-300 rounded w-1/3"></li>
                </ul>
              </div>
              
              {/* Phone Skeleton */}
              <div className="flex items-start space-x-2 mb-4">
                <div className="h-6 bg-gray-300 rounded w-1/4"></div>
                <ul className="space-y-2 w-full">
                  <li className="h-4 bg-gray-300 rounded w-1/2"></li>
                  <li className="h-4 bg-gray-300 rounded w-1/3"></li>
                </ul>
              </div>
              
              {/* Address Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-full mb-4"></div>
              
              {/* Notes Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
              
              {/* Contact Name Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-2/3 mb-4"></div>
              
              {/* Customer Type Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-1/4 mb-4"></div>
              
              {/* Marketing Opt-In Skeleton */}
              <div className="h-6 bg-gray-300 rounded w-1/3"></div>
            </div>
          </div>
          <div className="flex space-x-4 justify-end mt-6">
            {/* Buttons Skeleton */}
            <div className="h-10 bg-gray-300 rounded w-24"></div>
            <div className="h-10 bg-gray-300 rounded w-24"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsSkeleton;
