import React from 'react';

const SkeletonRow = () => (
  <tr className="animate-pulse">
    <td className="py-3 px-6">
      <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
      <div className="h-3 bg-gray-300 rounded w-1/4"></div>
    </td>
    <td className="py-3 px-6">
      <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
      <div className="h-4 bg-gray-300 rounded w-1/3"></div>
    </td>
    <td className="py-3 px-6">
      <div className="h-4 bg-gray-300 rounded w-2/3 mb-2"></div>
    </td>
    <td className="py-3 px-6 text-center">
      <div className="h-4 bg-gray-300 rounded w-1/3 mx-auto"></div>
    </td>
    <td className="py-3 px-6 text-center">
      <div className="h-8 bg-gray-300 rounded w-1/2 mx-auto"></div>
    </td>
  </tr>
);

const SkeletonTable = () => (
  <div className="container mx-auto bg-white shadow-md rounded my-6">
    <table className="min-w-max w-full table-auto">
      <tbody className="text-gray-600 text-sm font-light">
        {Array(5).fill(0).map((_, index) => (
          <SkeletonRow key={index} />
        ))}
      </tbody>
    </table>
  </div>
);

export default SkeletonTable;
